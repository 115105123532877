import Layout from '@/layout/layout'

const DemoVideoRouter = {
  path: '/safety',
  component: Layout,
  redirect: 'noRedirect',
  name: '保险内容配置',
  children: [
    {
      path:'register',
      component: () => import('@/views/safety/register/index'),
      name: '注册协议及说明',
      meta: { title: '注册协议及说明', noCache: true }
    },
    {
        path:'registerEdit',
        component: () => import('@/views/safety/register/edit'),
        name: '编辑',
        meta: { title: '编辑', noCache: true }
    },
    {
        path:'Precautions',
        component: () => import('@/views/safety/Precautions/index'),
        name: '注意事项',
        meta: { title: '注意事项', noCache: true }
    },
    {
        path:'PrecautionsEdit',
        component: () => import('@/views/safety/Precautions/edit'),
        name: '编辑',
        meta: { title: '编辑', noCache: true }
    },
    {
        path:'subInsuranceSetting',
        component: () => import('@/views/safety/register/subInsuranceSetting'),
        name: '子险种配置',
        meta: { title: '子险种配置', noCache: true }
    },
    {
        path:'idCardVerifyConfig',
        component: () => import('@/views/safety/register/idCardVerifyConfig'),
        name: '身份证验证配置',
        meta: { title: '身份证验证配置', noCache: true }
    },
      {
          path:'logisticsInfo',
          component: () => import('@/views/safety/logisticsInfo/index.vue'),
          name: '物流信息',
          meta: { title: '物流信息', noCache: true }
      },
      {
          path:'logisticsOrder',
          component: () => import('@/views/safety/logisticsOrder/index.vue'),
          name: '物流订单',
          meta: { title: '物流订单', noCache: true }
      },
  ]
}

export default DemoVideoRouter
