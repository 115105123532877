import Layout from '@/layout/layout'

const PartnerRouter = {
    path: '/partnerManage',
    component: Layout,
    redirect: 'noRedirect',
    name: '合作商管理',
    children: [
        {
            path:'list',
            component: () => import('@/views/partnerManage/list'),
            name: '合作商列表',
            meta: { title: '合作商列表', noCache: true }
        },  {
            path:'storeList',
            component: () => import('@/views/partnerManage/storeList'),
            name: '门店列表',
            meta: { title: '门店列表', noCache: true }
        },  {
            path:'registeredStaff',
            component: () => import('@/views/partnerManage/registeredStaff'),
            name: '注册员工',
            meta: { title: '注册员工', noCache: true }
        },  {
            path:'redBag',
            component: () => import('@/views/partnerManage/redBag'),
            name: '商家红包明细',
            meta: { title: '商家红包明细', noCache: true }
        },

    ]
}

export default PartnerRouter
